import BarChart from "./BarChart";
import SafeAware from "./SafeAware";
import NearbyPlaces from "./NearbyPlaces";
import React from "react";

const Safety = ({ data, location }) => {
    return (
        <div className="w-5/6">
            {data.crime_stats ? (
                <BarChart content={data.crime_stats.content} options={data.crime_stats.options} />
            ) : (
                <div>該当データなし</div>
            )}
            <div className="w-full h-96">
                <SafeAware data={data.safe_aware_crime_data}
                           center={{ lat: location.lat, lng: location.lng }} className="w-full h-96" />
            </div>
            <div className="w-full mt-8">
                <p>警察署・交番</p>
                <NearbyPlaces className="w-full" latitude={location.lat} longitude={location.lng} placeTypes={["police"]} />
            </div>
        </div>
    );
};

export default Safety;