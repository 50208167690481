import React from "react";
import SchoolMap from "./SchoolMap";

const Education = ({data, location}) => {
    return (
        <div className="w-5/6">
            <div className="w-full">
                {data.elementary_school_district.map((item, index) => (
                    <div key={index}>
                        {Array.isArray(item) && item.map(([key, value]) => (
                            <p key={key}>{key}: {value}</p>
                        ))}
                    </div>
                ))}
            </div>
            <div className="w-full mt-8 mb-8">
                {data.junior_high_school_district.map((item, index) => (
                    <div key={index}>
                        {Array.isArray(item) && item.map(([key, value]) => (
                            <p key={key}>{key}: {value}</p>
                        ))}
                    </div>
                ))}
            </div>
            <div className="w-full h-96　mt-8">
                <SchoolMap data={[...data.elementary_school_district, ...data.junior_high_school_district]}
                           center={{ lat: location.lat, lng: location.lng }} className="w-full h-96" />
            </div>
        </div>
    );
}
export default Education;