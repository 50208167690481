import React from 'react';
import { Link } from 'react-router-dom';

const CancelPage = () => {
    return (
        <div className="p-6 bg-white rounded-lg shadow-md">
            <h1>Payment Canceled</h1>
            <p>Your payment was not completed. If you would like to try again, please select one of the options
                below:</p>

            <div style={{marginTop: '20px'}}>
                {/* Link to the subscription or pricing page where users can choose a plan again */}
                <Link to="/pricing">
                    <button style={{marginRight: '10px'}}>Choose a Different Plan</button>
                </Link>

                {/* Link to retry the payment (could redirect to the checkout page again) */}
                <Link to="/retry">
                    <button style={{marginRight: '10px'}}>Retry Payment</button>
                </Link>

                {/* Link back to the homepage or another relevant part of the site */}
                <Link to="/">
                    <button>Return to Home</button>
                </Link>
            </div>
        </div>
    );
};

export default CancelPage;