import NearbyPlaces from "./NearbyPlaces";
import React from "react";


const Medical = ({data, location}) => {
    return (
        <div className="w-5/6">
            <div className="w-full mt-8">
                <p>病院</p>
                <NearbyPlaces className="w-full" latitude={location.lat} longitude={location.lng}
                              placeTypes={["hospital"]}/>
            </div>
            <div className="w-full mt-8">
                <p>住民一人当たりの医師数(予定)</p>
            </div>
        </div>
    );
};

export default Medical;