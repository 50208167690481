import React, { useState } from 'react';

const Sidebar = ({ setActiveCategory }) => {
    const [selectedCategory, setSelectedCategory] = useState('demography');

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setActiveCategory(category);
    };

    const buttonClass = (category) => (
        `w-full mb-2 p-2 text-white ${selectedCategory === category ? 'bg-blue-900' : 'bg-blue-500'} hover:bg-blue-600 transition-colors`
    );


    return (
        <div className="w-48 bg-gray-200 p-4">
            <button className={buttonClass('demography')} onClick={() => handleCategoryClick('demography')}>
                人々
            </button>
            <button className={buttonClass('life')} onClick={() => handleCategoryClick('life')}>
                生活
            </button>
            <button className={buttonClass('safety')} onClick={() => handleCategoryClick('safety')}>
                安全
            </button>
            <button className={buttonClass('education')} onClick={() => handleCategoryClick('education')}>
                教育
            </button>
            <button className={buttonClass('realEstate')} onClick={() => handleCategoryClick('realEstate')}>
                住まい
            </button>
            <button className={buttonClass('transportation')} onClick={() => handleCategoryClick('transportation')}>
                交通
            </button>
            <button className={buttonClass('medical')} onClick={() => handleCategoryClick('medical')}>
                医療
            </button>
            <button className={buttonClass('hazard')} onClick={() => handleCategoryClick('hazard')}>
                災害
            </button>
            <button className={buttonClass('weather')} onClick={() => handleCategoryClick('weather')}>
                天気
            </button>
        </div>
    );
};

export default Sidebar;