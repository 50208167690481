import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import axios from 'axios';
import 'mapbox-gl/dist/mapbox-gl.css';

const MapboxIsochrone = ({ latitude, longitude, profile }) => {
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);
  const mapboxToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

  const colors = {
    15: '#a8ddb5', // Light Greenish-Blue
    30: '#7bccc4', // Medium Greenish-Blue
    45: '#4eb3d3', // Light Blue
    60: '#2b8cbe', // Dark Blue
  };

  useEffect(() => {
    mapboxgl.accessToken = mapboxToken;

    const initializeMap = ({ setMap, mapContainerRef }) => {
      const mapInstance = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [longitude, latitude], // Initial map center (Longitude, Latitude)
        zoom: 10,
      });

      mapInstance.on('load', () => {
        setMap(mapInstance);
        mapInstance.resize();
        getIsochrone(mapInstance, longitude, latitude, profile);
        // Add a marker to the map at the specified coordinates
        new mapboxgl.Marker()
          .setLngLat([longitude, latitude])
          .addTo(mapInstance);
      });
    };

    if (!map) initializeMap({ setMap, mapContainerRef });
  }, [map, latitude, longitude]);

  const getIsochrone = async (map, lng, lat, profile) => {
    const coordinates = `${lng},${lat}`;
    const isochroneApiUrl = `https://api.mapbox.com/isochrone/v1/mapbox/${profile}/${coordinates}`;

    try {
      const response = await axios.get(isochroneApiUrl, {
        params: {
          contours_minutes: '15,30,45,60',
          polygons: true,
          access_token: mapboxToken,
        },
        paramsSerializer: params => {
          const searchParams = new URLSearchParams();
          for (const key in params) {
            searchParams.append(key, params[key]);
          }
          return searchParams.toString();
        },
      });

      const data = response.data;

      if (map) {
        if (!map.getSource('isochrones')) {
          map.addSource('isochrones', {
            type: 'geojson',
            data: data,
          });
        }

        data.features.forEach((feature, index) => {
          const minutes = feature.properties.contour;

          // Add layers only if they don't already exist
          if (!map.getLayer(`isochrone-${minutes}`)) {
            map.addLayer({
              id: `isochrone-${minutes}`,
              type: 'fill',
              source: 'isochrones',
              filter: ['==', 'contour', minutes],
              layout: {},
              paint: {
                'fill-color': colors[minutes], // Use the corresponding color for each interval
                'fill-opacity': 0.5,
                'fill-outline-color': '#1e40af', // Optional: Add a darker outline to the area
              },
            });
          }
        });
      }
    } catch (error) {
      console.error('Error fetching isochrone:', error);
    }
  };

  const Legend = ({ colors }) => {
    return (
      <div className="bg-white shadow-md rounded p-4 max-w-xs">
        {/*<h4 className="text-sm font-semibold mb-4">車での到達可能範囲</h4>*/}
        <div className="space-y-2">
          {Object.entries(colors).map(([minutes, color]) => (
            <div key={minutes} className="flex items-center">
              <span
                className="w-6 h-6 inline-block mr-3 rounded"
                style={{ backgroundColor: color }}
              ></span>
              <span className="text-sm">{minutes}分</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
      <div className="relative flex items-start justify-center h-96 w-full">
        <div className="absolute top-4 left-4 z-10">
          <Legend colors={colors}/>
        </div>
        <div className="h-full w-full" ref={mapContainerRef} />
      </div>
  );
};

export default MapboxIsochrone;