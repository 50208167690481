import React, { useState, useEffect } from 'react';
import { GoogleMap, MarkerF, InfoWindowF } from '@react-google-maps/api';

const SchoolMap = ({ data, center, className }) => {
    const [selected, setSelected] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [duration, setDuration] = useState(null);
    const [distance, setDistance] = useState(null);

    useEffect(() => {
        const geocodeAddress = async (address) => {
            const geocoder = new window.google.maps.Geocoder();
            return new Promise((resolve, reject) => {
                geocoder.geocode({ address: address }, (results, status) => {
                    if (status === "OK") {
                        resolve({
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng()
                        });
                    } else {
                        reject('Geocode was not successful for the following reason: ' + status);
                    }
                });
            });
        };

        const fetchMarkers = async () => {
            let markerData = [];

            for (let school of data) {
                const address = school.find(item => item[0] === '住所')[1];
                const name = school.find(item => item[0] === '名称')[1];
                try {
                    const coordinates = await geocodeAddress(address);
                    markerData.push({
                        id: `${name}-${address}`,
                        name: name,
                        address: address,
                        coordinates: coordinates
                    });
                } catch (error) {
                    console.error('Error fetching coordinates for address:', address, error);
                }
            }
            setMarkers(markerData);
        };

        fetchMarkers();
    }, [data]);

    const mapContainerStyle = {
        width: '100%',
        height: '100%'
    };

    const handleMarkerClick = (marker) => {
        setSelected(marker);

        // DistanceMatrixServiceを使って距離と所要時間をリクエスト
        const service = new window.google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
            {
                origins: [center],
                destinations: [marker.coordinates],
                travelMode: window.google.maps.TravelMode.WALKING,
                unitSystem: window.google.maps.UnitSystem.METRIC,
            },
            (response, status) => {
                if (status === window.google.maps.DistanceMatrixStatus.OK) {
                    const result = response.rows[0].elements[0];
                    setDuration(result.duration.text); // 徒歩時間を保存
                    setDistance(result.distance.text); // 距離を保存
                } else {
                    console.error(`Error fetching distance matrix: ${status}`);
                }
            }
        );
    };

    return (
        <div className={className} style={{ position: 'relative', overflow: 'hidden' }}>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={14}
            >
                <MarkerF
                    position={center}
                    icon={{
                        url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                    }}
                />

                {markers.map((marker, index) => (
                    <MarkerF
                        key={index}
                        position={marker.coordinates}
                        onClick={() => handleMarkerClick(marker)}
                        icon={{
                            url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png"
                        }}
                    />
                ))}

                {selected && (
                    <InfoWindowF
                        position={selected.coordinates}
                        onCloseClick={() => setSelected(null)}
                    >
                        <div className="p-2">
                            <h2 className="text-lg font-semibold">{selected.name}</h2>
                            <p className="text-sm">{selected.address}</p>
                            {duration && distance && (
                                <div>
                                    <p>徒歩時間: {duration}</p>
                                    <p>距離: {distance}</p>
                                </div>
                            )}
                        </div>
                    </InfoWindowF>
                )}
            </GoogleMap>
        </div>
    );
};

export default SchoolMap;
