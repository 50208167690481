import React from "react";
import { Chart } from "react-google-charts";


function LineChart({content, options}) {
  // console.log(content)
  // console.log(options)
  return (
    <Chart
      chartType="LineChart"
      width="100%"
      height="400px"
      data={content}
      options={options}
    />
  );
}

export default LineChart;