import React, {useState, useEffect} from 'react';
import {useUserData} from './UserContext';
import {
    auth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    sendEmailVerification,
    firestore,
    doc,
    getDoc,
    setDoc
} from './firebase.js';
import Autocomplete from './Autocomplete';

const UserProfile = ({errorMessage}) => {
    const {userData, setUserData} = useUserData();
    const [profile, setProfile] = useState(userData?.profile || {
        gender: '', generation: '', commutingAddress: ''
    });
    const [isEditing, setIsEditing] = useState(false);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setProfile((prevProfile) => ({
            ...prevProfile,
            [name]: value,
        }));
    };

    const handleProfileUpdate = async () => {
        if (userData) {
            const userRef = doc(firestore, "users", userData.user.uid);
            await setDoc(userRef, profile, {merge: true});
            setUserData({ ...userData, profile });
            setIsEditing(false);
            alert("Profile updated successfully!");
        }
    };

    const handleAddressSelect = async (addressData) => {
        const lat = addressData.geometry.location.lat();
        const lng = addressData.geometry.location.lng();

        setProfile((prevProfile) => ({
            ...prevProfile,
            commutingAddress: addressData.formatted_address,
            latitude: lat,
            longitude: lng,
        }));
    };

    if (!userData) {
        return <div>Please log in to see your profile.</div>;
    }

    return (
        <div className="user-profile max-w-lg mx-auto p-6 bg-white rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold mb-4">User Profile</h2>

            {errorMessage && (
                <p className="text-red-500 text-sm mb-4">
                    {errorMessage}
                </p>
            )}

            {isEditing ? (
                <div className="profile-edit space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            Gender:
                        </label>
                        <select
                            name="gender"
                            value={profile.gender}
                            onChange={handleInputChange}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            Generation:
                        </label>
                        <select
                            name="generation"
                            value={profile.generation}
                            onChange={handleInputChange}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                            <option value="">Select Generation</option>
                            <option value="Teen">Teen</option>
                            <option value="20th">20th</option>
                            <option value="30th">30th</option>
                            <option value="40th">40th</option>
                            <option value="50th">50th</option>
                            <option value="60th">60th</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            Commuting Address:
                        </label>
                        <Autocomplete onAddressSelect={handleAddressSelect}　defaultValue={profile.commutingAddress} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"/>
                        {/*<input*/}
                        {/*    type="text"*/}
                        {/*    name="commutingAddress"*/}
                        {/*    value={profile.commutingAddress}*/}
                        {/*    onChange={handleInputChange}*/}
                        {/*    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"*/}
                        {/*/>*/}
                    </div>
                    <div className="flex space-x-4">
                        <button
                            onClick={handleProfileUpdate}
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Save
                        </button>
                        <button
                            onClick={() => setIsEditing(false)}
                            className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            ) : (
                <div className="profile-view space-y-4">
                    <p className="text-gray-700"><strong>Gender:</strong> {profile.gender || "Not set"}</p>
                    <p className="text-gray-700"><strong>Generation:</strong> {profile.generation || "Not set"}</p>
                    <p className="text-gray-700"><strong>Commuting Address:</strong> {profile.commutingAddress || "Not set"}</p>
                    <button
                        onClick={() => setIsEditing(true)}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Edit Profile
                    </button>
                </div>
            )}
        </div>
    );
};

export default UserProfile;