import './App.css';
import React, {useState, useEffect} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import axios from 'axios';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {UserProvider, useUserData} from './UserContext';
import {
    auth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    sendEmailVerification,
    firestore,
    doc,
    getDoc,
    setDoc
} from './firebase.js';
import RegistrationForm from "./RegistrationForm";
import LoginForm from "./LoginForm";
import SubscriptionForm from "./SubscriptionForm"
import UserProfile from "./UserProfile";
import Modal from './Modal';
import {useLoadScript} from '@react-google-maps/api';
import Footer from "./Footer";
import Header from './Header';
import MainComponent from "./MainComponent";
import SuccessPage from "./SuccessPage"
import CancelPage from "./CancelPage"


const libraries = ["places"];

function MainApp() {
    const {isLoaded: isMapsLoaded, loadError: mapsLoadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: libraries,
        language: "ja"
    });
    const { userData, setUserData } = useUserData();
    const [isModalOpen, setModalOpen] = useState(false);
    const [authAction, setAuthAction] = useState('login'); // 'login', 'register', 'subscribe', or 'profile'
    const [errorMessage, setErrorMessage] = useState(''); // State to store error messages

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            const fetchAndSetUserProfile = async () => {
                if (user) {
                    await fetchUserProfile(setUserData); // Fetch and set the user profile if the user is logged in
                } else {
                    setUserData(null); // Clear the user state if no user is logged in
                }
            };

            fetchAndSetUserProfile(); // Call the async function inside the onAuthStateChanged callback
        });
        return () => unsubscribe();
    }, []);

    const handleRegister = async (email, password) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Send verification email
            await sendEmailVerification(user);

            // Sign out the user immediately after registration
            await signOut(auth);

            // Inform the user to check their email
            setErrorMessage("Registration successful! Please check your email to verify your account before logging in.");
            setModalOpen(false); // Optionally close the modal
        } catch (error) {
            console.error("Error registering user:", error.message);
            setErrorMessage(error.message); // Set the error message to be displayed
        }
    };

    const fetchUserProfile = async (setUserData) => {
        const currentUser = auth.currentUser; // Get the currently logged-in user
        if (currentUser) {
            const userRef = doc(firestore, "users", currentUser.uid);
            const userSnap = await getDoc(userRef);

            if (userSnap.exists()) {
                const profileData = userSnap.data();
                setUserData({ user: currentUser, profile: profileData });
            } else {
                setUserData({ user: currentUser, profile: null });
            }
        }
    };

    const handleLogin = async (email, password) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const currentUser = userCredential.user;

            if (currentUser.emailVerified) {
                await fetchUserProfile(setUserData); // Fetch profile after login
                setModalOpen(false);
            } else {
                setErrorMessage("Please verify your email address before logging in.");
                await signOut(auth); // Immediately sign out the user if they are not verified
            }
        } catch (error) {
            console.error("Error logging in user:", error.message);
            setErrorMessage(error.message);
        }
    };

    const handleLogout = async () => {
        await signOut(auth);
        setUserData(null);
    };

    const handleSelectPlan = async (priceId) => {
        try {
            const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
            const url = `${process.env.REACT_APP_API_URL}create-checkout-session/`
            let idToken = "anonymous";

            if (userData) {
                try {
                    idToken = await userData.user.getIdToken();  // Override idToken if the user is logged in
                } catch (error) {
                    console.error("Error getting ID token:", error);
                    // Optionally handle the error or set idToken to a different fallback value
                }
            }

            // Fetch the Checkout session from the backend using axios
            console.log(priceId)
            const response = await axios.post(url, {price_id: priceId,}, {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                        "Content-Type": "application/json",
                    }
                }
            );

            if (priceId === "cancel_subscription") {
                // Handle the cancellation logic
                const {message, subscription_id} = response.data;
                console.log(`Cancellation confirmed: ${message}`);
            } else {
                const session = response.data;

                // Redirect to Stripe Checkout
                const result = await stripe.redirectToCheckout({
                    sessionId: session.sessionId,
                });

                if (result.error) {
                    console.error(result.error.message);
                }

                // Optionally handle UI updates or notifications
                //setModalOpen(false); // Close the modal after redirect
            }
        } catch (error) {
            console.error("Error during plan selection:", error);
            setErrorMessage("Failed to initiate the subscription. Please try again.");
        }
    };

    const renderModalContent = () => {
        switch (authAction) {
            case 'register':
                return <RegistrationForm onRegister={handleRegister} errorMessage={errorMessage}/>;
            case 'login':
                return <LoginForm onLogin={handleLogin} errorMessage={errorMessage}/>;
            case 'subscribe':
                return <SubscriptionForm onSelectPlan={handleSelectPlan} onClose={() => setModalOpen(false)}/>;
            case 'profile':
                return <UserProfile errorMessage={errorMessage}/>;
            default:
                return null;
        }
    };

    if (mapsLoadError) return <div>Error loading maps</div>;
    if (!isMapsLoaded) return <div>Loading Maps...</div>;

    return (
        <Router>
            <div className="App w-full mx-auto py-4 px-2 bg-gray-100">
                <Header
                    setModalOpen={setModalOpen}
                    setErrorMessage={setErrorMessage}
                    setAuthAction={setAuthAction}
                    handleLogout={handleLogout}
                />
                <div>

                    {isModalOpen && (
                        <Modal onClose={() => setModalOpen(false)}>
                            {renderModalContent()}
                        </Modal>
                    )}

                </div>

                <Routes>
                    <Route path="/" element={
                        <div>
                            <MainComponent/>
                            <div className="p-6 bg-white rounded-lg shadow-md">
                                <h1 className="text-3xl font-bold text-gray-800 mb-4">使い方説明</h1>
                                <p className="text-base text-gray-600 mb-2">住所入力欄に住所を入力してください</p>
                                <div className="bg-blue-200 text-blue-800 p-4 rounded-md">
                                    <p>対応エリア：東京都</p>
                                </div>
                            </div>
                        </div>
                    }/>
                    <Route path="/trade" element={
                        <div className="p-6 bg-white rounded-lg shadow-md">
                            <iframe
                                src="/trade.html"
                                title="特定商取引法に基づく表記"
                                width="100%"
                                height="600px"
                                frameBorder="0"
                                className="border-2 border-gray-300"
                            ></iframe>
                        </div>

                    }/>
                    <Route path="/success" element={<SuccessPage />} />
                    <Route path="/cancel" element={<CancelPage />} />
                </Routes>

                <Footer/>
            </div>
        </Router>
    )
}

export default function App() {
    return (
        <UserProvider>
            <MainApp/>
        </UserProvider>
    );
}