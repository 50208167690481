import React from "react";
import Shelter from "./Shelter";

const Hazard = ({data, location}) => {
    return (
        <div className="w-5/6">
            <div className="w-full">
                <p className={data.dense_urban_area[0]["密集市街地"] === "範囲内" ? "text-red-500 font-bold" : "text-black"}>
                    密集市街地: {data.dense_urban_area[0]["密集市街地"]}
                </p>
            </div>
            <div className="w-full">
                <div>
                    {/* Display the main status */}
                    <p className={data.landslide_disaster_alert_area["土砂災害警戒区域"] === "区域内" ? "text-red-500 font-bold" : "text-black"}>
                        土砂災害警戒区域: {data.landslide_disaster_alert_area["土砂災害警戒区域"]}
                    </p>

                    {/* Display the details if available */}
                    {data.landslide_disaster_alert_area["土砂災害警戒区域"] === "区域内" && data.landslide_disaster_alert_area["詳細"] && (
                        <div className="mt-2">
                            {data.landslide_disaster_alert_area["詳細"].map(([key, value], index) => (
                                <p key={index} className="text-red-500">
                                    {key}: {value}
                                </p>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div className="w-full">
                <div>
                    {data.storm_surge_flood_zone.map((item, index) => (
                        <div key={index}>
                            {item.map(([key, value]) => (
                                <p key={key}
                                   className={value !== "なし" ? "text-red-500 font-bold" : "text-black"}>
                                    {key}: {value}
                                </p>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-full">
                <div>
                    {data.hazard_area.map((item, index) => (
                        <div key={index}
                             className={item["災害危険区域"] === "範囲内" ? "text-red-500 font-bold" : "text-black"}>
                            {Object.entries(item).map(([key, value]) => (
                                <p key={key}>
                                    {key}: {value}
                                </p>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-full">
                <div>
                    {data.flood_inundation_by_river.map((item, index) => (
                        <div key={index}
                             className={item["洪水浸水想定区域（河川単位）"] !== "該当なし" ? "text-red-500 font-bold" : "text-black"}>
                            {Object.entries(item).map(([key, value]) => (
                                <p key={key}>
                                    {key}: {value}
                                </p>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-full">
                <div>
                    {data.steep_slope_collapse_hazard_area.map((item, index) => (
                        <div key={index}
                             className={item["急傾斜地崩壊危険区域"] === "範囲内" ? "text-red-500 font-bold" : "text-black"}>
                            <p>
                                急傾斜地崩壊危険区域: {item["急傾斜地崩壊危険区域"]}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-full">
                <div>
                    {data.large_fill_slope_area.map((item, index) => (
                        <div key={index}
                             className={item["大規模盛土造成地"] === "範囲内" ? "text-red-500 font-bold" : "text-black"}>
                            <p>
                                大規模盛土造成地: {item["大規模盛土造成地"]}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-full">
                <div>
                    {data.low_lying_area.map((item, index) => (
                        <div key={index}
                             className={item["低位地帯 最大浸水深"] ? "text-red-500 font-bold" : "text-black"}>
                            {item["低位地帯 最大浸水深"] !== undefined ? (
                                <p>低位地帯 最大浸水深: {item["低位地帯 最大浸水深"]}m</p>
                            ) : (
                                <p>低位地帯: {item["低位地帯"]}</p>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-full">
                <div>
                    {data.landslide_protection_zone.map((item, index) => (
                        <div key={index}
                             className={item["地すべり防止区域"] === "範囲内" ? "text-red-500 font-bold" : "text-black"}>
                            <p>
                                地すべり防止区域: {item["地すべり防止区域"]}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-full mt-8">
                <Shelter data={data.shelter}
                           center={{ lat: location.lat, lng: location.lng }} className="w-full h-96" />
            </div>
        </div>
    );
};

export default Hazard;