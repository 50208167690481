import React, {useRef, useEffect} from 'react';


function Autocomplete({onAddressSelect, defaultValue, className}) {

    const autocompleteInputRef = useRef(null);

    useEffect(() => {

        // Initialize Google Places Autocomplete
        const autocomplete = new window.google.maps.places.Autocomplete(
            autocompleteInputRef.current,
            {
                componentRestrictions: {country: "jp"},
                fields: ["formatted_address", "geometry.location", "address_components"]
            } // Use types to specify result types
        );
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            console.log(place); // Handle the selected place result
            onAddressSelect(place)
        });

        // Set the default value in the input field if it exists
        if (defaultValue && autocompleteInputRef.current) {
            autocompleteInputRef.current.value = defaultValue;
        }
    }, [onAddressSelect, defaultValue]);

    return (
        <input
            ref={autocompleteInputRef}
            type="text"
            placeholder="住所を入力してください"
            className={className} // Apply passed className
        />
    );
}

export default Autocomplete;