import React from 'react';
import footerImage from './assets/images/tokyo-4358758_1920.png';

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white">
            {/* Image at the top of the footer spanning the entire width */}
            <div className="w-full">
                <img src={footerImage} alt="Footer Image" className="w-full h-auto"/>
            </div>
            <div className="container mx-auto py-4 px-4">
                <div className="flex flex-wrap justify-between">
                    <div className="w-full md:w-1/3 mb-6 md:mb-0">
                        <h2 className="text-lg font-semibold mb-2">会社情報</h2>
                        <p className="text-sm">
                            〒101-0064 東京都千代田区神田猿楽町２−８−１１VORT水道橋Ⅲ
                        </p>
                        <p className="text-sm">電話番号: 03-1234-5678</p>
                        <p className="text-sm">メール: info@example.com</p>
                    </div>
                    <div className="w-full md:w-1/3 mb-6 md:mb-0">
                        <h2 className="text-lg font-semibold mb-2">リンク</h2>
                        <ul className="text-sm">
                            <li className="mb-1"><a href="#" className="hover:underline">利用規約</a></li>
                            <li className="mb-1"><a href="#" className="hover:underline">プライバシーポリシー</a></li>
                            <li className="mb-1"><a href="/trade" className="hover:underline">特商法に基づく表示</a>
                            </li>
                            <li className="mb-1"><a href="#" className="hover:underline">お問い合わせ</a></li>
                            <li className="mb-1"><a href="#" className="hover:underline">サイトマップ</a></li>
                        </ul>
                    </div>
                    <div className="w-full md:w-1/3 flex flex-col items-center">
                        <h2 className="text-lg font-semibold mb-2">SNS</h2>
                        <ul className="flex space-x-4 text-sm">
                            <li><a href="#" className="hover:underline">Twitter</a></li>
                            <li><a href="#" className="hover:underline">Facebook</a></li>
                            <li><a href="#" className="hover:underline">Instagram</a></li>
                        </ul>
                    </div>
                </div>
                <div className="border-t border-gray-700 mt-8 pt-4 text-center">
                    <p className="text-sm">© 2024 Borzoi AI株式会社. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;