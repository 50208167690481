import React, { useEffect, useState } from 'react';
import { GoogleMap, MarkerF, InfoWindowF } from '@react-google-maps/api';


const mapContainerStyle = {
  height: '400px',
  width: '100%',
};

const NearbyPlaces = ({className, latitude, longitude, placeTypes = [] }) => {
  const [places, setPlaces] = useState([]);
  const center = { lat: latitude, lng: longitude };
  const [selectedMarker, setSelectedMarker] = useState(null);

  useEffect(() => {
    const fetchNearbyPlaces = () => {
      const service = new window.google.maps.places.PlacesService(document.createElement('div'));

      const promises = placeTypes.map((placeType) => {
        return new Promise((resolve, reject) => {
          const request = {
            location: new window.google.maps.LatLng(latitude, longitude),
            type: placeType, // Search for the specified place type
            rankBy: window.google.maps.places.RankBy.DISTANCE,
          };

          service.nearbySearch(request, (results, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              // Map through results and get details for each place
              const detailPromises = results.map((place) => {
                return new Promise((detailResolve) => {
                  const detailRequest = {
                    placeId: place.place_id,
                    fields: ['name', 'vicinity', 'url', 'geometry', 'types'], // Include 'url' in the requested fields
                  };

                  service.getDetails(detailRequest, (placeDetails, detailStatus) => {
                    if (detailStatus === window.google.maps.places.PlacesServiceStatus.OK) {
                      detailResolve(placeDetails);
                    } else {
                      console.error(`Error fetching details for place ID ${place.place_id}:`, detailStatus);
                      detailResolve(place); // Resolve with the original place if details fail
                    }
                  });
                });
              });

              // Wait for all detail requests to complete
              Promise.all(detailPromises).then((detailedPlaces) => {
                resolve(detailedPlaces);
              });
            } else {
              console.error(`Error fetching nearby places for type ${placeType}:`, status);
              resolve([]); // Resolve with an empty array on error
            }
          });
        });
      });

      Promise.all(promises).then((results) => {
        const combinedResults = results.flat(); // Combine all results into one array
        setPlaces(combinedResults);
      });
    };

    if (window.google && placeTypes.length > 0) {
      fetchNearbyPlaces();
    }
  }, [latitude, longitude, placeTypes]);

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const markerIcon = "http://maps.google.com/mapfiles/ms/icons/red-dot.png";

  return (
    <div className={className} style={{position: 'relative', overflow: 'hidden'}}>
      {/*<h2>Nearby {placeType.charAt(0).toUpperCase() + placeType.slice(1)}s</h2>*/}
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={16}
      >
        <MarkerF
          key="center"
          position={center}
          title="Current Location"
          icon={{
            url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
          }}
        />
        {places && places.map((place) => (
          <MarkerF
            key={place.place_id}
            position={{
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            }}
            title={place.name}
            icon={{
              url: markerIcon,
            }}
            onClick={() => {
              console.log('Clicked Marker Index:', place.type);
              handleMarkerClick(place)
            }}
          />
        ))}
        {selectedMarker && (
          <InfoWindowF
            position={{
              lat: selectedMarker.geometry.location.lat(),
              lng: selectedMarker.geometry.location.lng(),
            }}
            onCloseClick={() => setSelectedMarker(null)}
          >
            <div style={{maxWidth: '500px', padding: '10px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
              <strong>{selectedMarker.name}</strong><br/><br/>
              {selectedMarker.vicinity}<br/>
              <a href={selectedMarker.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                Google Mapで開く
              </a><br/>
            </div>
          </InfoWindowF>
        )}
      </GoogleMap>
    </div>
  );
};

export default NearbyPlaces;