import React, { useEffect, useRef } from 'react';


const MapStreetView = ({ lat, lng, height = '400px', width = '100%' }) => {
  const mapRef = useRef(null);
  const panoRef = useRef(null);

  useEffect(() => {
    if (!lat || !lng) return;

    if (mapRef.current && panoRef.current && lat && lng) {
      const location = { lat, lng };

      const map = new window.google.maps.Map(mapRef.current, {
        center: location,
        zoom: 14,
      });

      const panorama = new window.google.maps.StreetViewPanorama(panoRef.current, {
        position: location,
        pov: {
          heading: 34,
          pitch: 10,
        },
      });

      map.setStreetView(panorama);
      }

  }, [lat, lng]);

  // Render the map and panorama containers only when lat and lng are defined
  if (!lat || !lng) {
    return null;
  }

  return (
      <div style={{display: 'flex', flexDirection: 'row', width}}>
        <div ref={mapRef} style={{height, width: '50%', backgroundColor: '#e0e0e0'}}></div>
        <div ref={panoRef} style={{height, width: '50%', backgroundColor: '#e0e0e0'}}></div>
      </div>
  );
};

export default MapStreetView;