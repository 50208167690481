import React from 'react';
import logo from './assets/images/logo.png';
import {useUserData} from './UserContext';
import { Link } from 'react-router-dom';

const Header = ({setModalOpen, setErrorMessage, setAuthAction, handleLogout}) => {
    const {userData} = useUserData();

    return (
        <header className="relative flex items-center h-28 bg-gray-200 text-gray-800 p-4 shadow-md">
            {/* Logo Section */}
            <div className="absolute left-1/2 transform -translate-x-1/2">
                <Link to="/">
                    <img src={logo} alt="引越しナビ Logo" className="w-20 h-auto"/>
                </Link>
            </div>
            {/* User Action Buttons */}
            <div className="ml-auto flex items-center space-x-4">
                {!userData ? (
                    <>
                        <button
                            onClick={() => {
                                setModalOpen(true);
                                setErrorMessage('');
                                setAuthAction('register');
                            }}
                            className="bg-blue-700 hover:bg-blue-800 text-white px-4 py-2 rounded-md transition-colors"
                        >
                            User Registration
                        </button>
                        <button
                            onClick={() => {
                                setModalOpen(true);
                                setErrorMessage('');
                                setAuthAction('login');
                            }}
                            className="bg-blue-700 hover:bg-blue-800 text-white px-4 py-2 rounded-md transition-colors"
                        >
                            Login
                        </button>
                    </>
                ) : (
                    <>
                        <button
                            onClick={() => {
                                setModalOpen(true);
                                setErrorMessage('');
                                setAuthAction('subscribe');
                            }}
                            className="bg-blue-700 hover:bg-blue-800 text-white px-4 py-2 rounded-md transition-colors"
                        >
                            Subscribe
                        </button>
                        <button
                            onClick={() => {
                                setModalOpen(true);
                                setErrorMessage('');
                                setAuthAction('profile');
                            }}
                            className="bg-blue-700 hover:bg-blue-800 text-white px-4 py-2 rounded-md transition-colors"
                        >
                            Profile
                        </button>
                        <button
                            onClick={handleLogout}
                            className="bg-blue-700 hover:bg-blue-800 text-white px-4 py-2 rounded-md transition-colors"
                        >
                            Logout
                        </button>
                    </>
                )}
            </div>
        </header>
    );
};

export default Header;