import React, {useEffect, useState} from 'react';
import NearbyPlaces from "./NearbyPlaces";
import MapboxIsochrone from "./MapboxIsochrone";
import {useUserData} from './UserContext';

const Transportation = ({data, location }) => {
    const [drivingCommuteTime, setDrivingCommuteTime] = useState(null);
    const [transitCommuteTime, setTransitCommuteTime] = useState(null);
    const {userData} = useUserData();

    useEffect(() => {
        console.log(userData.profile)
        // Ensure that location and commutingAddress with latitude and longitude are available
        if (!location || !userData?.profile?.latitude || !userData?.profile?.longitude) {
            return;
        }

        console.log(userData.profile)

        const originLatLng = new window.google.maps.LatLng(location.lat, location.lng);
        const destinationLatLng = new window.google.maps.LatLng(
            userData.profile.latitude,
            userData.profile.longitude
        );
        const service = new window.google.maps.DirectionsService();
        service.route(
            {
                origin: originLatLng,
                destination: destinationLatLng,
                travelMode: 'DRIVING',
                region: 'JP',
                unitSystem: window.google.maps.UnitSystem.METRIC,
            },
            (response, status) => {
                if (status === 'OK' && response.status === 'OK') {
                    console.log(response.routes[0].legs[0].duration.text)
                    setDrivingCommuteTime(response.routes[0].legs[0].duration.text);
                } else {
                    setDrivingCommuteTime('Commute time not available');
                }
            }
        );
    }, [location]);


    return (
        <div className="w-5/6">
            <div className="w-full mt-8">
                <p>最寄り駅</p>
                <NearbyPlaces className="w-full" latitude={location.lat} longitude={location.lng}
                              placeTypes={["train_station", "subway_station"]}/>
            </div>
            <div className="w-full mt-8 w-11/12">
                <p>車による到達可能範囲</p>
                <MapboxIsochrone latitude={location.lat} longitude={location.lng}
                                 profile="driving-traffic"/>
            </div>

            {/*{transitCommuteTime && (*/}
            {/*    <div className="w-full mt-4 p-4 bg-gray-100 rounded-md shadow-md">*/}
            {/*        <h3 className="text-xl font-bold text-gray-800 mb-2">公共交通機関による通勤時間</h3>*/}
            {/*        <p className="text-gray-700">Transit Commute Time: {transitCommuteTime}</p>*/}
            {/*    </div>*/}
            {/*)}*/}

            {drivingCommuteTime && (
                <div className="w-full mt-4 p-4 bg-gray-100 rounded-md shadow-md">
                    <h3 className="text-l font-bold text-gray-800 mb-2">車による通勤・通学時間</h3>
                    <p className="text-gray-700">{drivingCommuteTime}</p>
                </div>
            )}
        </div>
    );
};

export default Transportation;