import React, {useState} from 'react';
import {GoogleMap, MarkerF, InfoWindowF} from '@react-google-maps/api';
import thiefIcon from './assets/images/thief_90.png';
import hackerIcon from './assets/images/hacker_90.png';
import pervertIcon from './assets/images/pervert_90.png';

const SafeAware = ({data, center, className}) => {
    const [selected, setSelected] = useState(null);

    const markerIcons = {
      公然わいせつ: pervertIcon,
      犯罪: hackerIcon,
      不審者: thiefIcon,
      default: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png"
    };

    function getMarkerIcon(type) {
      return markerIcons[type] || markerIcons.default;
    }


    // Log data to debug its structure
    //console.log('SafeAware data:', data);

    const mapContainerStyle = {
        width: '100%',
        height: '100%'
    };

    const handleMarkerClick = (marker) => {
        setSelected(marker);
    };


    return (
        <div className={className} style={{position: 'relative', overflow: 'hidden'}}>
            <div><p>犯罪発生マップ</p></div>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={12}
            >
                <MarkerF
                    position={center}
                    icon={{
                        url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png" // Optional: Different icon for center marker
                    }}
                    // onClick={() => setSelected({subject: "Center Location", ...center})}
                />
                {data && data.map((marker) => {
                    // Check if marker and coordinates exist
                    if (!marker || !marker.location || !marker.location.coordinates || marker.location.coordinates.length < 2) {
                        console.error('Invalid marker data:', marker);
                        return null;
                    }
                    // console.log('Marker position:', {
                    //     lat: marker.location.coordinates[1],
                    //     lng: marker.location.coordinates[0]
                    // });
                    return (
                        <MarkerF
                            key={marker.id}
                            position={{
                                lat: marker.location.coordinates[1],
                                lng: marker.location.coordinates[0]
                            }}
                            icon={{
                                url: getMarkerIcon(marker.type),
                                scaledSize: new window.google.maps.Size(30, 30),
                            }}
                            onClick={() => handleMarkerClick(marker)
                            }
                        />
                    );
                })}
                {selected && (
                    <InfoWindowF
                        position={{
                            lat: selected.location.coordinates[1],
                            lng: selected.location.coordinates[0]
                        }}
                        onCloseClick={() => setSelected(null)}
                    >
                        <div className="p-2">
                            <h2 className="text-lg font-semibold">{selected.type}</h2>
                            <p className="text-sm">{selected.details}</p>
                            <p className="text-sm">{selected.address}</p>
                            <p className="text-sm">{selected.date_time}</p>
                        </div>
                    </InfoWindowF>
                )}
            </GoogleMap>
        </div>
    );
};

export default SafeAware;