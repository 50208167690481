import React from "react";
import AreaChart from "./AreaChart";


const Weather = ({data, location}) => {
    return (
        <div className="w-5/6">
            {data.precipitation && (
                <div className="w-full">
                    <AreaChart content={data.precipitation.content}
                               options={data.precipitation.options}/>
                </div>
            )}
            {data.temperature && (
                <div className="w-full">
                    <AreaChart content={data.temperature.content}
                               options={data.temperature.options}/>
                </div>
            )}
            {data.snow_accumulation && (
                <div className="w-full">
                    <AreaChart content={data.snow_accumulation.content}
                               options={data.snow_accumulation.options}/>
                </div>
            )}
            {data.sunshine_time && (
                <div className="w-full">
                    <AreaChart content={data.sunshine_time.content}
                               options={data.sunshine_time.options}/>
                </div>
            )}
            {data.solar_radiation && (
                <div className="w-full">
                    <AreaChart content={data.solar_radiation.content}
                               options={data.solar_radiation.options}/>
                </div>
            )}
        </div>
    );
};

export default Weather;