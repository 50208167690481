import './App.css';
import React, {useState} from 'react';
import axios from 'axios';
import {useUserData} from './UserContext';
import Autocomplete from './Autocomplete';
import MapStreetView from './MapStreetView';
import Safety from './Safety';
import Life from "./Life";
import Education from "./Education";
import Transportation from "./Transportation"
import Medical from "./Medical";
import Weather from "./Weather";
import Demography from "./Demography"
import Hazard from "./Hazard"
import RealEstate from "./RealEstate";

function AddressData({ activeCategory }) {
    const [address, setAddress] = useState('');
    const [location, setLocation] = useState({lat: null, lng: null});
    const [elevation, setElevation] = useState(null);
    const [data, setData] = useState([]);
    const [status, setStatus] = useState('IDLE'); // 'IDLE', 'LOADING', 'LOADED'
    const [error, setError] = useState(null);
    // Use the environment variable for the API URL
    const API_URL = process.env.REACT_APP_API_URL;
    const API_ACCESS_TOKEN = process.env.REACT_APP_SERVER_ACCESS_TOKEN
    const {userData} = useUserData();

    const renderCategory = () => {
        switch (activeCategory) {
            case 'weather':
                return <Weather data={data} location={location} />;
            case 'life':
                return <Life data={data} location={location} />;
            case 'demography':
                return <Demography data={data} location={location} />;
            case 'safety':
                return <Safety data={data} location={location} />;
            case 'education':
                return <Education data={data} location={location} />;
            case 'realEstate':
                return <RealEstate data={data} location={location} />;
            case 'transportation':
                return <Transportation data={data} location={location} />;
            case 'medical':
                return <Medical data={data} location={location} />;
            case 'hazard':
                return <Hazard data={data} location={location} />;
            default:
                return <p>選択されたカテゴリがありません</p>;
        }
    };

    const handleAddressSelect = async (addressData) => {
        setStatus('LOADING');
        setError(null);

        console.log('Address selected:', addressData);

        const sublocalityLevel2 = addressData.address_components.find(component =>
            component.types.includes('sublocality_level_2'));

        if (!sublocalityLevel2) {
            setError("住所を町名まで指定してください");
            console.warn('address does not have sublocality_level_2');
            setStatus('IDLE');
            return;
        }

        const lat = addressData.geometry.location.lat();
        const lng = addressData.geometry.location.lng();
        const location = { lat, lng };
        setLocation(location);

        const getElevation = (location) => {
            const elevator = new window.google.maps.ElevationService();

            elevator.getElevationForLocations({
                locations: [location],
            }, (results, status) => {
                if (status === 'OK' && results[0]) {
                    setElevation(results[0].elevation);
                } else {
                    console.error('Elevation service failed due to:', status);
                }
            });
        };

        getElevation(location);

        const url = `${API_URL}get_address/`
        let idToken = "anonymous";

        if (userData) {
            try {
                idToken = await userData.user.getIdToken();  // Override idToken if the user is logged in
            } catch (error) {
                console.error("Error getting ID token:", error);
                // Optionally handle the error or set idToken to a different fallback value
            }
        }

        try {
            const response = await axios.post(url, addressData, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            });
            //console.log('API call successful:', response.data.test_json);
            setData(response.data.test_json);
        } catch (error) {
            setError(error.message);
            console.error('API call failed:', error, url);
        } finally {
            setStatus('LOADED');
        }
    };

    return (
        <div className="w-full ${className}">
            <Autocomplete onAddressSelect={handleAddressSelect} className="w-full md:w-1/2 px-4 py-2 border rounded-md" />
            <div className="flex justify-center">
                <div className="mt-4 w-11/12">
                    <MapStreetView lat={location.lat} lng={location.lng} height="380px" width="100%"/>
                </div>
            </div>
            {elevation !== null && (
                <div>
                    <h3>標高: {elevation.toFixed(2)} メートル</h3>
                </div>
            )}
            {/*<div className="mt-4 w-full">*/}
            {/*    <Map3D lat={location.lat} lng={location.lng} className="map-container"/>*/}
            {/*</div>*/}
            <div>
                {status === 'LOADING' && (

                    <div className="flex items-center justify-center space-x-2 text-gray-500 mt-4">
                        <svg className="animate-spin h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg"
                             fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                    strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                        </svg>
                        <p className="text-lg font-medium">データロード中</p>
                    </div>
                )}
                {status === 'LOADED' && !error && (
                    <div className="flex flex-col items-center justify-center space-y-8 mt-8">
                        <div className="mt-4 w-11/12 h-auto">
                            <p>{data.introduction}</p>
                        </div>
                        <div className="w-full p-4">
                            {renderCategory()}
                        </div>
                    </div>
                )}
                {error && <p className="text-red-500">{error}</p>}
            </div>
        </div>
    )
}

export default AddressData;
