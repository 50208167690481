import React from "react";
import LandValue from "./LandValue";


const RealEstate = ({data, location}) => {
    return (
        <div className="w-5/6">
            <div className="w-full">
                {data.area_zoned_for_use.map((item, index) => (
                    <div key={index}>
                        {Array.isArray(item) && item.map(([key, value]) => (
                            <p key={key}>{key}: {value}</p>
                        ))}
                    </div>
                ))}
            </div>
            <div className="w-full h-96">
                <LandValue data={data.land_value}
                           center={{lat: location.lat, lng: location.lng}} className="w-full h-96"/>
            </div>
            <div className="w-full mt-8">
                <p>間取り別平均家賃</p>
            </div>
        </div>
    );
};

export default RealEstate;