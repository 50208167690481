import React from "react";
import AreaChart from "./AreaChart";
import PieChart from "./PieChart";
import BarChart from "./BarChart";
import LineChart from "./LineChart";


const Demography = ({data, location}) => {
    return (
        <div className="w-5/6">
            <div className="w-full">
                <BarChart content={data.yearly_income.content}
                          options={data.yearly_income.options}/>
            </div>
            <div className="w-full">
                <BarChart content={data.population_per_age_group.content}
                          options={data.population_per_age_group.options}/>
            </div>
            <div className="w-full">
                <PieChart content={data.population_per_final_education.content}
                          options={data.population_per_final_education.options}/>
            </div>
            <div className="w-full">
                <PieChart content={data.alien_population.content}
                          options={data.alien_population.options}/>
            </div>
            <div className="w-full">
                <PieChart content={data.martial_status.content}
                          options={data.martial_status.options}/>
            </div>
            <div className="w-full">
                <BarChart content={data.number_of_people_per_family.content}
                          options={data.number_of_people_per_family.options}/>
            </div>
            <div className="w-full">
                <BarChart content={data.number_of_families_per_housing_type.content}
                          options={data.number_of_families_per_housing_type.options}/>
            </div>
            <div className="w-full">
                <LineChart content={data.predicted_population_over_time.content}
                           options={data.predicted_population_over_time.options}/>
            </div>
            <div className="w-full">
                <AreaChart content={data.predicted_population_per_age_group.content}
                           options={data.predicted_population_per_age_group.options}/>
            </div>
            <div className="w-full">
                <PieChart content={data.industry_classification_by_population.content}
                          options={data.industry_classification_by_population.options}/>
            </div>
            <div className="w-full">
                <PieChart content={data.number_of_employees_by_industry.content}
                          options={data.number_of_employees_by_industry.options}/>
            </div>
            <div className="w-full">
                <PieChart
                    content={data.number_of_employees_and_commuters_by_workplace_and_school.content}
                    options={data.number_of_employees_and_commuters_by_workplace_and_school.options}/>
            </div>
            <div className="w-full">
                <PieChart content={data.number_of_commuters_by_mode_of_transportation.content}
                          options={data.number_of_commuters_by_mode_of_transportation.options}/>
            </div>
            <div className="w-full">
                <PieChart content={data.population_per_length_of_residence.content}
                          options={data.population_per_length_of_residence.options}/>
            </div>
            <div className="w-full">
                <PieChart content={data.population_per_occupation.content}
                          options={data.population_per_occupation.options}/>
            </div>
        </div>
    );
};

export default Demography;