import React, { useState } from 'react';
import Sidebar from './Sidebar'; // Adjust import path as needed
import AddressData from "./AddressData"; // Adjust import path as needed
import Autocomplete from './Autocomplete';
import {useUserData} from "./UserContext";

const MainComponent = ({ data, location }) => {
    const [activeCategory, setActiveCategory] = useState('demography');
    const {userData} = useUserData();

    return (
        <div className="flex flex-row">
            <Sidebar setActiveCategory={setActiveCategory}/>
            <div className="flex flex-col w-3/4 p-4">
                <div className="flex flex-row w-full">
                    <div className="w-1/2 p-2">
                        <AddressData activeCategory={activeCategory} />
                    </div>
                    <div className="w-1/2 p-2">
                        <AddressData activeCategory={activeCategory} />
                    </div>
                </div>
            {activeCategory === 'transportation' && userData && (
                <div className="mb-4">
                    <h3 className="text-lg font-semibold mb-2">通勤・通学地の住所</h3>
                    {userData.profile?.commutingAddress}
                </div>
            )}
            </div>
        </div>
    );
};

export default MainComponent;