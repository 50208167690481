import React, { useState, useEffect } from 'react';
import {useUserData} from "./UserContext";
import axios from 'axios';


const SubscriptionForm = ({ onSelectPlan, onClose }) => {
    const { userData } = useUserData();  // Access the user directly
    const [currentPlanId, setCurrentPlanId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const API_URL = process.env.REACT_APP_API_URL;
    const url = `${API_URL}get-subscription-info/`

    const plans = [
        { id: 'price_1Pogmv06yZndSRKgAE0Mycdt', name: 'ベーシック（月額）', price: '￥1,000/月' },
        { id: 'price_1PogoP06yZndSRKgZgYivAJf', name: 'ベーシック（年額）', price: '￥10,000/年' },
        { id: 'price_1Pogow06yZndSRKgPnvcAzlw', name: 'プレミアム（月額）', price: '￥5,000/月' },
        { id: 'price_1PogpE06yZndSRKgnHQlJdFQ', name: 'プレミアム（年額）', price: '￥50,000/年' },
    ];

    useEffect(() => {
        if (userData && !currentPlanId) {  // Fetch current plan only if user is logged in and no plan is loaded
            const fetchCurrentPlan = async () => {
                try {
                    const idToken = await userData.user.getIdToken(); // Assuming user.getIdToken() is available
                    const response = await axios.get(url, {
                        headers: {
                            'Authorization': `Bearer ${idToken}`
                        }
                    });

                    if (response.data && !response.data.error) {
                        console.log(response.data)
                        setCurrentPlanId(response.data.plan_id);
                    } else {
                        setError(response.data.error || "No active subscription found.");
                    }
                } catch (error) {
                    setError("Error fetching subscription info.");
                    console.error("Error:", error);
                } finally {
                    setLoading(false);
                }
            };

            fetchCurrentPlan();
        } else {
            setLoading(false);
        }
    }, [userData, currentPlanId]);

    if (loading) {
        return <p>Loading your subscription information...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div>
            <h2 className="text-2xl font-bold mb-4">Choose a Subscription Plan</h2>
            <ul className="space-y-4">
                {plans.map((plan) => (
                    <li
                        key={plan.id}
                        className={`border rounded-md p-4 ${plan.id === currentPlanId ? 'bg-green-100' : ''}`}
                    >
                        <div className="flex justify-between items-center">
                            <div>
                                <h3 className="text-lg font-semibold">
                                    {plan.name} {plan.id === currentPlanId && <span className="text-sm text-green-600">(Current Plan)</span>}
                                </h3>
                                <p className="text-gray-600">{plan.price}</p>
                            </div>
                            <button
                                onClick={() => onSelectPlan(plan.id)}
                                className={`py-2 px-4 rounded-md transition-colors ${plan.id === currentPlanId ? 'bg-gray-500 text-white cursor-not-allowed' : 'bg-blue-600 text-white hover:bg-blue-700'}`}
                                disabled={plan.id === currentPlanId}
                            >
                                {plan.id === currentPlanId ? 'Selected' : 'Select'}
                            </button>
                        </div>
                    </li>
                ))}
                {currentPlanId && (
                    <li className="border rounded-md p-4 bg-red-100">
                        <div className="flex justify-between items-center">
                            <div>
                                <h3 className="text-lg font-semibold text-red-600">キャンセル</h3>
                                <p className="text-gray-600">Cancel Subscription</p>
                            </div>
                            <button
                                onClick={() => onSelectPlan('cancel_subscription')}
                                className="bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-700 transition-colors"
                            >
                                Cancel
                            </button>
                        </div>
                    </li>
                )}
            </ul>
            <button
                onClick={onClose}
                className="mt-6 bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600 transition-colors"
            >
                Close
            </button>
        </div>
    );
};

export default SubscriptionForm;