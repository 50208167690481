import NearbyPlaces from "./NearbyPlaces";
import AreaChart from "./AreaChart";
import React from "react";


const Life = ({data, location}) => {
    return (
        <div className="w-5/6">
            <div className="w-full mt-8">
                <p>スーパー</p>
                <NearbyPlaces className="w-full" latitude={location.lat} longitude={location.lng}
                              placeTypes={["supermarket"]}/>
            </div>
            <div className="w-full mt-8">
                <p>コンビニ</p>
                <NearbyPlaces className="w-full" latitude={location.lat} longitude={location.lng}
                              placeTypes={["convenience_store"]}/>
            </div>
            <div className="w-full mt-8">
                <p>公園</p>
                <NearbyPlaces className="w-full" latitude={location.lat} longitude={location.lng}
                              placeTypes={["park"]}/>
            </div>
            <div className="w-full mt-8">
                <p>生活費（予定）</p>
            </div>
            <div className="w-full mt-8">
                <div className="w-full">
                    <AreaChart content={data.infux_of_people_day.content}
                               options={data.infux_of_people_day.options}/>
                </div>
                <div className="w-full">
                    <AreaChart content={data.infux_of_people_time.content}
                               options={data.infux_of_people_time.options}/>
                </div>
            </div>
        </div>
    );
};

export default Life;