import React, { useState, useEffect } from 'react';
import { GoogleMap, MarkerF, InfoWindowF } from '@react-google-maps/api';

const Shelter = ({ data, center, className }) => {
    const [selected, setSelected] = useState(null);
    const [shelters, setShelters] = useState([]);
    const [duration, setDuration] = useState(null);
    const [distance, setDistance] = useState(null);

    useEffect(() => {
        if (data && Array.isArray(data)) {
            const formattedData = data.map(shelter => {
                const shelterObject = {};
                shelter.forEach(item => {
                    shelterObject[item[0]] = item[1];
                });
                return shelterObject;
            });
            setShelters(formattedData);
        }
    }, [data]);

    const mapContainerStyle = {
        width: '100%',
        height: '100%'
    };

    const handleMarkerClick = (shelter) => {
        setSelected(shelter);

        // DistanceMatrixServiceを使って距離と所要時間をリクエスト
        const service = new window.google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
            {
                origins: [center],
                destinations: [{ lat: shelter['緯度'], lng: shelter['経度'] }],
                travelMode: window.google.maps.TravelMode.WALKING,
                unitSystem: window.google.maps.UnitSystem.METRIC,
            },
            (response, status) => {
                if (status === window.google.maps.DistanceMatrixStatus.OK) {
                    const result = response.rows[0].elements[0];
                    setDuration(result.duration.text); // 徒歩時間を保存
                    setDistance(result.distance.text); // 距離を保存
                } else {
                    console.error(`Error fetching distance matrix: ${status}`);
                }
            }
        );
    };

    return (
        <div className={className} style={{ position: 'relative', overflow: 'hidden' }}>
            <div><p>最寄りの避難所マップ</p></div>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={12}
            >
                <MarkerF
                    position={center}
                    icon={{
                        url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                    }}
                />

                {shelters.map((shelter, index) => (
                    <MarkerF
                        key={index}
                        position={{
                            lat: shelter['緯度'],
                            lng: shelter['経度']
                        }}
                        onClick={() => handleMarkerClick(shelter)}
                        icon={{
                            url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png"
                        }}
                    />
                ))}

                {selected && (
                    <InfoWindowF
                        position={{
                            lat: selected['緯度'],
                            lng: selected['経度']
                        }}
                        onCloseClick={() => setSelected(null)}
                    >
                        <div className="p-2">
                            <h2 className="text-lg font-semibold">{selected['名称']}</h2>
                            <p className="text-sm">{selected['施設の種類']}</p>
                            {duration && distance && (
                                <div>
                                    <p>徒歩時間: {duration}</p>
                                    <p>距離: {distance}</p>
                                </div>
                            )}
                        </div>
                    </InfoWindowF>
                )}
            </GoogleMap>
        </div>
    );
};

export default Shelter;
