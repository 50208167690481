import React, {useState} from 'react';
import {GoogleMap, MarkerF, InfoWindowF} from '@react-google-maps/api';


const LandValue = ({data, center, className}) => {
    const [selected, setSelected] = useState(null);

    // Log data to debug its structure
    //console.log('LandValue data:', data);

    const mapContainerStyle = {
        width: '100%',
        height: '100%'
    };

    const handleMarkerClick = (marker) => {
        setSelected(marker);
    };

    return (
        <div className={className} style={{position: 'relative', overflow: 'hidden'}}>
            <div><p>都道府県地価調査データ</p></div>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={12}
            >
                <MarkerF
                    position={center}
                    icon={{
                        url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                    }}
                    // onClick={() => setSelected({subject: "Center Location", ...center})}
                />
                {data && data.map((marker) => {
                    // Check if marker and coordinates exist
                    if (!marker || !marker.location || !marker.location.coordinates || marker.location.coordinates.length < 2) {
                        console.error('Invalid marker data:', marker);
                        return null;
                    }
                    // console.log('Marker position:', {
                    //     lat: marker.location.coordinates[1],
                    //     lng: marker.location.coordinates[0]
                    // });
                    return (
                        <MarkerF
                            key={marker.location.coordinates[0] + marker.location.coordinates[1]}
                            position={{
                                lat: marker.location.coordinates[1],
                                lng: marker.location.coordinates[0]
                            }}
                            onClick={() => handleMarkerClick(marker)}
                        />
                    );
                })}
                {selected && (
                    <InfoWindowF
                        position={{
                            lat: selected.location.coordinates[1],
                            lng: selected.location.coordinates[0]
                        }}
                        onCloseClick={() => setSelected(null)}
                    >
                        <div className="p-2">
                            <h2 className="text-lg font-semibold">{selected.value.toLocaleString()}円/ｍ2</h2>
                            <p className="text-sm">前年度比：{selected.value_change}%</p>
                            <p className="text-sm">{selected.address}</p>
                            <p className="text-sm">用途区分：{selected.purpose}</p>
                            <p className="text-sm">利用の現況：{selected.current_use}</p>
                            <p className="text-sm">利用の詳細：{selected.current_use_detail}</p>
                            <p className="text-sm">利用区分：{selected.usage_classification}</p>
                            <p className="text-sm">建物構造：{selected.building_structure}</p>
                        </div>
                    </InfoWindowF>
                )}
            </GoogleMap>
        </div>
    );
};

export default LandValue;